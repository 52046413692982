<template>
  <div 
    id="overview"
    ref="container"
    v-resize="onResize"
  >
    <v-row>
      <v-col>
        <v-row
          ref="main-row"
        >
          <v-col
            v-for="(card, p) in list"
            :key="p"
            cols="12"
            :sm="6"
            :md="cards.gallery.toggle ? 6 : 4"
          >
            <data-overview-card 
              :title="$t(`dashboard.${p}.title`)"
              :chart="card.chart"
              :length="card.chart.length"
              :number="card.number"
              :color="card.color"
              :loading="card.loading"
              :route="card.route"
              class="fill-height"
              @click="open"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col 
        v-if="cards.gallery.toggle"
        cols="4"
      >
        <data-overview-card 
          :title="$t(cards.gallery.title)"
          :chart="cards.gallery.chart"
          :length="cards.gallery.chart.length"
          :number="cards.gallery.number"
          :color="cards.gallery.color"
          :loading="cards.gallery.loading"
          :route="cards.gallery.route"
          :height="cards.gallery.height"
          class="fill-height"
          @click="open"
        />
      </v-col>
    </v-row>
    <v-overlay 
      :value="loading"
      color="white"
      absolute
      opacity=".8"
      class="loading-overlay text-center"
    >
      <loading class="loading mb-4" />
      <span class="d-block text-overline grey--text text--lighten-1">
        Carregando
      </span>
    </v-overlay>
  </div>
</template>

<script>
  import services from '@/services'
  import { overview as getData, demographics as getDemographics } from '@/api/dashboard';
  import { list as getGallery } from '@/api/gallery';
  import { sync } from 'vuex-pathify'

    // import mock from '@/mock'

  export default {
    name: 'DashboardOverview',

    props: {
      pages: {
        type: Object,
        default: () => {
          return {}
        }
      },
      dataKey: {
        type: String,
        default: null
      },
      period: {
        type: Array,
        default: () => null
      },
      duration: {
        type: Array,
        default: () => null
      },
      scope: {
        type: Number,
        default: 0
      },
      children: {
        type: Array,
        default: () => null
      },
      segment: {
        type: Number,
        default: 0
      },
      segments: {
        type: Object,
        default: () => {}
      },
      user: {
        type: Object,
        default: () => {}
      },
      stored: {
        type: Object,
        default: () => {}
      },
      budget: {
        type: Number,
        default: 0
      },
      impressions: {
        type: Number,
        default: 0
      },
      nav: {
        type: Boolean,
        default: false
      },
      report: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      cards: {
        audience: {
          route: 'audience',
          order: 0,
          color: 'primary',
          chart: {
            type: 'Sparkline',
            bar: false,
            data: null,
            options: {
              colors: ['#698DF2']
            },
            length: 30
          },
          size: {
            md: 6,
            sm: 6,
            default: 12,
          },
          number: {
            value: null,
            format: '0.0 a',
            hint: {
              text: '',
              color: 'success'
            }
          },
        },
        demographics: {
          route: 'demographics',
          order: 1,
          color: ['accent', 'primary'],
          chart: {
            type: 'SimpleColumn',
            data: [0, 0],
            length: 2
          },
          size: {
            md: 6,
            sm: 6,
            default: 12,
          },
          number: [
            {
              value: null,
              format: '0.0 a',
              hint: {
                text: '',
                color: 'grey'
              }
            },
            {
              value: null,
              format: '0.0 a',
              hint: {
                text: '',
                color: 'grey'
              }
            }
          ],
        },
        impressions: {
          route: 'impressions',
          order: 2,
          color: 'primary',
          chart: {
            type: 'Sparkline',
            bar: false,
            data: null,
            length: 30,
            options: {
              vAxis: { viewWindow: { min: 0, max: 100 } },
              colors: [
                '#DEDEDE',
                '#38C7C2',
              ],
              series: {
                0: { lineDashStyle: [4, 4], pointSize: 0 },
              },
            },
          },
          size: {
            md: 6,
            sm: 6,
            default: 12,
          },
          number: {
            value: null,
            format: '0.0 a',
            hint: {
              text: '',
              color: 'grey'
            }
          },
        },
        spent: {
          route: 'budget',
          order: 3,
          color: 'secondary',
          chart: {
            type: 'Sparkline',
            bar: false,
            data: null,
            options: {
              vAxis: { viewWindow: { min: 0, max: 100 } },
              colors: [
                '#DEDEDE',
                '#38C7C2',
              ],
              series: {
                0: { lineDashStyle: [4, 4], pointSize: 0 },
              },
            },
            length: 30
          },
          size: {
            md: 6,
            sm: 6,
            default: 12,
          },
          number: {
            value: null,
            format: '0.0%',
            hint: {
              text: '',
              color: 'grey'
            }
          },
          roles: [1,5,6,7,17]
        },
        cpm: {
          route: 'cpm',
          order: 4,
          color: 'secondary',
          chart: {
            type: 'SimpleColumn',
            data: null,
            length: 7
          },
          size: {
            md: 6,
            sm: 6,
            default: 12,
          },
          number: {
            value: 0,
            format: '$ 0.00',
            hint: {
              text: '',
              color: 'grey'
            }
          },
          roles: [1,5,6,7,17]
        },
        airtime: {
          route: 'hours',
          order: 5,
          color: 'primary',
          chart: {
            type: 'SimpleColumn',
            data: null,
            length: 7
          },
          size: {
            md: 6,
            sm: 6,
            default: 12,
          },
          number: {
            value: 0,
            format: '0.0 a',
            hint: {
              text: '',
              color: 'grey'
            }
          },
        },
        gallery: {
          toggle: false,
          title: 'dashboard.gallery.title',
          route: 'gallery',
          order: 6,
          color: 'primary',
          chart: {
            type: 'Gallery',
            data: [],
            hint: ''
          },
          height: null,
          number: null
        },
      },
      pipeline: {
        daily: { 
          get: 'getData',
          set: 'setData',
          key: null,
          loading: false,
          updated: false
        },
        demographics: { 
          get: 'getDemographics',
          set: 'setDemographics',
          key: null,
          loading: false,
          updated: false
        },
        gallery: {
          get: 'getGallery',
          set: 'setGallery',
          key: null,
          loading: false,
          updated: false
        },
      },
      source: {
        min: null,
        max: null,
        prototype: {
          daily: null,
          demographics: null,
        }
      },
      view: {
        debounce: {
          delay: 1000,
          resizing: false,
          timer: null
        }
      }
    }),

    computed: {
      toast: sync('app/toast'),

      list () {
        const roles = this.user.roles;
        return _.pickBy(this.cards, (card, c) => {
          return c!='gallery' && (!_.has(card, 'roles') || _.size(_.intersection(card.roles, roles))>0) && (!_.has(card, 'toggle')||card.toggle);
        })
      },

      admin () {
        const roles = this.user.roles;
        return _.size(_.intersection([1,5], roles))>0;
      },

      loading () {
        return _.some(this.pipeline, 'loading');
      },
      updated () {
        return _.every(this.pipeline, 'updated');
      },
    },

    watch: {
      dataKey: {
        immediate: true,
        handler (k) {
          if (this.period!=null) {
            this.clearData();
            this.updateView();
          }
        }
      },
      nav (b) {
        this.onResize();
        console.log('nav', b);
      },
      updated: {
        immediate: true,
        handler (b) {
          this.$emit('update', b);
        }
      }
    },

    methods: {
      ...services,
      getData,
      getDemographics,
      getGallery,

      setGallery (key, source, store) {
        const metric = 'gallery'
        const card = this.cards[metric];
        this.pipeline.gallery.key = key;

        if (!this.admin) source = _.filter(source, d => _.indexOf([1,2], d.status)>=0)
        this.source[key]['gallery'] = source;

        card.chart.data = _.orderBy(source, ['updated'], ['desc']);

        const total = _.size(source);
        const pending = _.size(_.filter(source, ['status', null]));
        card.chart.hint = this.$tc('dashboard.gallery.overview', this.admin ? 1 : 0, { n: total-pending, t: total })
        card.toggle = this.report===false&&total>0;

        if (_.isNil(store)||store) this.storeData('gallery', source);
      },

      setData (key, source, store) {
        console.log(key, source);
        this.pipeline.daily.key = key;
        this.source[key]['daily'] = source;

        if (_.isEmpty(source)) {
          this.toggleToast(
            true,
            this.$t('dashboard.no_data'),
            5000,
            false,
          );
          return null;
        }else{
          source = Object.freeze(_.orderBy(source, ['day'], ['asc']));
        }

        // set audience
        this.setAudience(source);

        // set impressions
        this.setImpressions(source);
        
        // set budget
        this.setBudget(source);
        
        // set cpm
        this.setCpm(source);
        
        // set airtime
        this.setAirtime(source);

        if (_.isNil(store)||store) this.storeData('daily', source);

        this.loadNext();
      },

      setAudience (data) {
        const metric = 'audience';
        const card = this.cards[metric];

        let total = 0;

        data = _.map(data, (d) => {
          total += d.audience;
          return {
            timestamp: d.day,
            audience: d.audience,
            total
          };
        })

        // set total
        card.number.value = total;

        // set hint
        const period = {
          start: _.first(data).timestamp,
          end: _.last(data).timestamp,
        };
        period.duration = this.$moment(period.end).diff(period.start, 'd');
        period.duration = period.duration >= 14 ? 7 : _.floor(data.length/2);

        let hint = period.duration == 0 ? this.$t('general.total') : ('signal delta% ' + this.$tc('dashboard.audience.variation', period.duration));

        period.old = data.length>1 ? data[data.length-period.duration].total : data[0].total;
        period.new = _.last(data).total;
        let delta = period.new - period.old;
        delta = _.round((delta / period.old) * 100);

        const signal = delta >= 0 ? '&#8593;' : '&#8595;';

        hint = _.replace(hint, 'signal', signal);
        hint = _.replace(hint, 'delta', delta);
        hint = _.replace(hint, 'DD', period.duration);

        card.number.hint.text = hint;
        card.number.hint.color = period.duration != 0 ? delta >= 0 ? 'success' : 'error' : 'grey';

        // console.log(data);

        // set chart
        card.chart.data = [
          ['date', 'audience'],
          ..._.map(data, d => [d.timestamp, (d.total/total)*100])
        ];
      },

      setDemographics (key, source, store) {
        const metric = 'demographics';
        const card = this.cards[metric];
        this.pipeline[metric].key = key;
        this.source[key][metric] = source;

        // set numbers
        let female = _.isNil(source) ? stored.female : source.F;
        let male = _.isNil(source) ? stored.male : source.M;

        card.number[0].hint.text = this.$numeral(female).format('0[,]0[.]0 a') + ' ' + this.$t('dashboard.charts.gender.women').toLowerCase();
        card.number[1].hint.text = this.$numeral(male).format('0[,]0[.]0 a') + ' ' + this.$t('dashboard.charts.gender.men').toLowerCase();

        const total = _.isNil(male)||_.isNil(female) ? 0 : male + female;
        female = total>0 ? _.round((female/total)*100, 1) : 50;
        male = total>0 ? _.round((male/total)*100, 1) : 50;
        
        card.number[0].value = female;
        card.number[1].value = male;

        const max = _.max([female, male]);
        female = (total>0 ? _.round((female/max)*100, 1) : 100) + '%';
        male = (total>0 ? _.round((male/max)*100, 1) : 100) + '%';

        card.chart.data = [female, male];

        if (_.isNil(store)||store) this.storeData('demographics', Object.freeze(source));

        this.loadNext();
      },

      setImpressions (data) {
        const metric = 'impressions';
        const card = this.cards[metric];
        const contracted = this.impressions;
        let total = 0;

        data = _.map(data, (d) => {
          total += d.impressions;
          const percent = contracted==0 ? total : (total / this.impressions) * 100;
          return {
            timestamp: d.day,
            impressions: d.impressions,
            total,
            percent
          };
        });

        // set total
        card.number.value = total;

        // set hint
        card.number.hint.text = contracted > 0 && total > 0 ? this.$numeral((total/contracted)).format('0.0%') + ' ' + this.$t('dashboard.impressions.of_total_contracted') : null;

        // set chart
        const period = {
          start: _.first(data).timestamp,
          end: _.last(data).timestamp,
        };
        period.duration = this.$moment(this.duration[1]).diff(this.duration[0], 'd') + 1;

        card.chart.options.vAxis.viewWindow.max = contracted==0 ? total : _.max([100, _.last(data).percent]);

        card.chart.data = [
          ['date', 'planned', 'impressions'],
          ..._.times(period.duration, n => {
            const day = this.$moment(this.duration[0]).add(n, 'd').format('YYYY-MM-DD');
            const d = _.find(data, ['timestamp', day]);
            const planned = contracted==0 ? 0 : ((n+1) / period.duration) * 100;
            return _.isNil(d) ? [day, planned, null] : [d.timestamp, planned, d.percent];
          })
        ];
      },

      setBudget (data) {
        const metric = 'spent';
        const card = this.cards[metric];

        let total = 0;

        data = _.map(data, (d) => {
          total += d.spent;
          const percent = this.budget==0 ? total : (total / this.budget) * 100;
          return {
            timestamp: d.day,
            spent: d.spent,
            total,
            percent
          };
        });

        // set total
        card.number.value = this.budget==0 ? total : (total / this.budget);
        if (this.budget==0) {
          card.number.format = '$ 0.0 a'
        }else{
          card.number.format = '0.0%'
          // set hint
          card.number.hint.text = this.$numeral(total).format('$ 0.0 a');
        }


        // set chart
        const period = {
          start: _.first(data).timestamp,
          end: _.last(data).timestamp,
        };
        period.duration = this.$moment(this.duration[1]).diff(this.duration[0], 'd') + 1;

        card.chart.options.vAxis.viewWindow.max = _.max([100, _.last(data).percent]);

        card.chart.data = [
          ['date', 'planned', 'spent'],
          ..._.times(period.duration, n => {
            const day = this.$moment(this.duration[0]).add(n, 'd').format('YYYY-MM-DD');
            const d = _.find(data, ['timestamp', day]);
            const planned = ((n+1) / period.duration) * 100;
            return _.isNil(d) ? [day, planned, null] : [d.timestamp, planned, d.percent];
          })
        ];
      },

      setCpm (data) {
        const metric = 'cpm';
        const card = this.cards[metric];

        const total = (_.sumBy(data, 'spent') / _.sumBy(data, 'audience')) * 1000;
        data = _.map(data, (d) => {
          return {
            timestamp: d.day,
            cpm: d.cpm,
            total,
          };
        });

        // set number
        card.number.value = total;

        // set hint
        const period = {
          start: _.first(data).timestamp,
          end: _.last(data).timestamp,
        };
        period.duration = this.$moment(period.end).diff(period.start, 'd')+1;
        period.duration = period.duration >= 7 ? 7 : period.duration;

        const last = _.slice(data, data.length-(period.duration), data.length);

        const avg = this.$numeral(_.meanBy(last, 'cpm')).format('$ 0.00');

        let hint = period.duration != 1 ? this.$t('dashboard.cpm.last_days_avg') : this.$t('dashboard.cpm.avg');
        hint = _.replace(hint, 'CPM', avg);
        hint = _.replace(hint, 'DD', period.duration);

        card.number.hint.text = hint;

        const max = _.maxBy(last, 'cpm').cpm;

        // set chart
        card.chart.data = _.map(last, d => ((d.cpm / max) * 100) + '%');
      },

      setAirtime (data) {
        const metric = 'airtime';
        const card = this.cards[metric];

        let total = _.sumBy(data, 'hours') / 60 / 60;

        // set number
        card.number.value = total;

        // set hint
        const period = {
          start: _.first(data).day,
          end: _.last(data).day,
        };
        period.duration = this.$moment(period.end).diff(period.start, 'd')+1;
        const avg = total / period.duration;

        let hint = this.$t('dashboard.airtime.avg', { avg: _.round(avg, 1) });
        card.number.hint.text = hint;

        // set chart
        period.duration = period.duration >= 7 ? 7 : period.duration;

        const last = _.slice(data, data.length-(period.duration), data.length);
        const max = _.maxBy(last, 'hours').hours;

        card.chart.data = _.map(last, d => ((d.hours / max) * 100) + '%');
      },

      storeData (dimension, source) {
        console.log('store', dimension);
        const key = this.dataKey.toString();
        this.$emit('store-data', {
          key, 
          dimension,
          source
        })
      },

      getStoredData (key, dimension) {
        return _.has(this.stored, dimension) && this.stored[dimension].key==key ? this.stored[dimension] : null;
      },

      updateView () {
        this.loadNext();
      },

      clearData () {
        _.each(this.pipeline, p => {
          p.updated = false;
        });
        _.each(this.cards, (card, c) => {
          if (c=='demographics') {
            card.chart.data = [];
            card.number[0].value = null;
            card.number[1].value = null;
            card.number[0].hint.text = '';
            card.number[1].hint.text = '';
          }else{
            // card.chart.data = null;
            if (!_.isNil(card.number)) {
              card.number.value = null;
              card.number.hint.text = '';
            }
          }
        });
      },

      loadNext () {
        const $ = this;
        const s = this.dataKey.toString();
        if (!_.has(this.source, s)) {
          this.source[s] = Object.assign({}, this.source.prototype);
        }
        _.each(this.pipeline, (p, k) => {
          const store = $.getStoredData(s, k);
          if (p.key!=s||!p.updated&&!p.loading) {
            if (!_.isNil(store)) {
              const updated = this.$moment().diff(this.$moment(store.timestamp), 'minutes')<5;
              console.log('stored', k, updated);
              p.updated = updated;
              p.loading = !updated;
              $[p.set](s, store.source, false);
              if (updated) return false;
            }
            let params = [$.scope, $.children, $.period,];
            if (k=='gallery') {
              params = [$.scope];
            }
            p.loading = true;
            $[p.get](
              $.user.auth.token,
              $.dataKey.toString(),
              ...params,
              (key, data) => {
                if (key==s) {
                  p.updated = true;
                  $[p.set](key, data);
                }
              },
              (error) => {
                let msg = this.$t('messages.waiting_response');
                if (error.response.status==404) {
                  p.updated = true;
                  p.key = s;
                  this.source[s][k] = [];
                  msg = _.has(error.response.data, 'message') ? error.response.data.message : this.$t('dashboard.demographics.no_data');
                }else{
                  setTimeout(($) => $.loadNext(), 5000, this);
                }
                setTimeout(($) => {
                  this.handleError(error, msg, [
                    true,
                    msg,
                    7000,
                    false
                  ]);
                  setTimeout(($) => $.loadNext(), 5000, $);
                }, 5000, this);
              },
              () => {
                p.loading = false;
              }
            );
            return false;
          }
        });
      },

      open (path) {
        const params = this.$route.params;
        path = `/${params.brand}/dashboard/${path}/${params.campaign}/${params.period}${_.has(params, 'groups')&&!_.isNil(params.groups) ? '/'+params.groups : ''}`
        console.log(path)
        this.$router.push({
          path,
          params: this.$route.params
        })
      },

      onResize () {
        if (!this.view.debounce.resizing) {
          this.view.debounce.resizing = true;
          this.view.debounce.timer = setTimeout(($) => {
            const height = _.has($.$refs, 'container') ? $.$refs['container'].clientHeight : window.innerHeight * .9;
            const main = _.has($.$refs, 'main-row') ? $.$refs['main-row'].clientHeight : 0;
            $.cards.gallery.height = main < height - 48 ? main - 24 : height - 48;
            $.view.debounce.resizing = false;
            $.view.debounce.timer = clearTimeout($.view.debounce.timer);
          }, this.view.debounce.delay, this);
        }
      }
    },

    mounted () {
      this.onResize();
    },

    updated () {
      this.onResize();
    },

    beforeDestroy () {
      if (this.view.debounce.timer!=null) this.view.debounce.timer = clearTimeout(this.view.debounce.timer);
      // this.data = {};
    },

    components: {
      DataOverviewCard: () => import('@/components/DataOverviewCard'),
      Loading: () => import('@/components/IconLoading'),
    }
  }
</script>
